@import "../../assets/scss/layouts/mixins";

.customized-menu{
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;  
  min-width: 156px;
  a {
    text-decoration: none;
  }

  .customized-menu-item {  
    padding: 10px;        
    color: black;   
    text-decoration: none;
    font-size: 0.775rem;
    &:hover {
      background-color: lightgray;            
    }
  }
}

.menu-button {
  display: flex;
  flex-direction: row;
  align-items: center;  
  padding: 8px;
  cursor: pointer;  
  font-size: 0.775rem;

  @include bpmin(desktopmed) {
    font-size: 0.875rem;
  }
  
  &:hover {
    background-color: #f5f5f5;
  }


}

.arrow_icon {
  pointer-events: none;
  width: 0.6em !important;
  display: none !important;

  @include bpmin(desktopmed) {
    display: inline-block !important;
    width: 1em !important;
  }

}