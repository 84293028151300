$desktopmin: 'min-width: 1280px';
$desktopmed: 'min-width: 1130px';
$desktopsmmed: 'min-width: 1050px';
$desktopsmmin: 'min-width: 1000px';
$laptopmin: 'min-width: 960px';
$tabletmin: 'min-width: 768px';
$tabletsmmin: 'min-width: 600px';
$mobilemin: 'min-width: 480px';


@mixin bpmin($point) {
    @if $point == desktop {
      @media screen and ($desktopmin) {
        @content;
      }
    }

    @else if $point == desktopmed {
      @media screen and ($desktopmed) {
        @content;
      }
    }
    
    @else if $point == desktopsmmed {
      @media screen and ($desktopsmmed) {
        @content;
      }
    }
    @else if $point == desktopsm {
      @media screen and ($desktopsmmin) {
        @content;
      }
    }
  
    @else if $point == laptop {
      @media screen and ($laptopmin) {
        @content;
      }
    }
  
    @else if $point == tablet {
      @media screen and ($tabletmin) {
        @content;
      }
    }
  
    @else if $point == tabletsm {
      @media screen and ($tabletsmmin) {
        @content;
      }
    }
  
    @else if $point == mobile {
      @media screen and ($mobilemin) {
        @content;
      }
    }
  
    @else {
      @media screen and (min-width: $point) {
        @content;
      }
    }
  }