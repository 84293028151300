footer.mainsite {
    background-color: #fff;
    color: #101212;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin: 0 auto -30px;
    padding: 0 0 30px;
    max-width: 2130px;
}

footer.mainsite .row1 {
    margin: 0 30px 60px;
    padding-top: 80px;
}

footer.mainsite.landingpage .row1 .column1 {
    text-align: center;
}

footer.mainsite.landingpage .row1 .column1 .link {
display: inline-block;
    }

@media (min-width: 620px) {
    footer.mainsite .row1 .column2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        margin-top: 45px;

    }

    footer.mainsite.landingpage .row1 .column2 {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

}

@media (max-width: 819px) {
    .footernavcol:last-child {
        grid-column: 1 / -1;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: 10px;
        }
    }
    footer.mainsite.landingpage .row1 .column2 .footernavcol:last-child {
        grid-column: auto;
    }

}

@media (min-width: 820px) {

    footer.mainsite .row1 .column2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
        justify-content: right;

    }

    footer.mainsite.landingpage .row1 .column2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        justify-content: right;

    }

}

@media (min-width: 1024px) {
    footer.mainsite .row1 {
        display: grid;
        grid-template-columns: auto 702px;
        grid-gap: 45px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 45px 60px;
    }

    footer.mainsite.landingpage .row1 {
        grid-template-columns: auto 350px;
    }

    footer.mainsite .row1 .column2 {
        margin-top: 0;
    }
}

footer.mainsite .row1 .column2 ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

footer.mainsite .row1 .column2 ul li strong {
    margin-bottom: 10px;
    display: inline-block;
}

footer.mainsite .row1 .column2 ul li a {
    color: #101212;
    text-decoration: none;
}

footer.mainsite .footer-logo {
    width: 100%;
    max-width: 210px;
}

footer.mainsite .landingpage-footer-logo {
    width: 100%;
    max-width: 325px;
}

@media (max-width: 619px) {
    footer.mainsite .footer-logo {
        margin: 0 auto 45px;
    }

    footer.mainsite .footernavcol {
        text-align: center;
        margin-bottom: 45px;
    }
}

footer.mainsite .row2 {
    border-top: 1px solid #101212;
    padding-top: 30px;
    margin: 30px 30px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    grid-gap: 30px;
}

@media (min-width: 620px) {
    footer.mainsite .row2 {
        grid-template-columns: auto auto;
        text-align: left;
    }
}

@media (min-width: 1024px) {
    footer.mainsite .row2 {
        margin: 30px 45px;
    }
}

footer.mainsite .row2 .column2 a {
    color: #101212;
    text-decoration: none;
}

.footerbuttons {
    text-align: right;
}

.footericonlink {
    margin: 0 4px;
}

.footericon {
    max-width: 25px;
}

.popupHeaderSection {
    background-color: #000;
    color: #fff;
    position: relative;
    
    h2 {
        padding-right: 30px;
    }

    p {
        font-size: 18px;
    }

    .popupFindStockist {
        text-transform: uppercase;
        font-size: 16px;
    }

    .popupCloseButton {
        position: absolute;
        right: 5px;
        top: 5px;
    }
}


.MuiButton-root.popupSubmitButton {
    background: #fff;
    border: 1px solid #333;
    color: #333;
    padding: 6px 25px;

    &:hover {
        background: #333;
        color: #fff;
    }
}

.MuiFormControl-root.popupformfield {
    margin-bottom: 10px;
    width: 100%;
}

.MuiGrid-item.popupformblock1 {
    margin-bottom: 30px;
}

div.hiddenField {
    display: none;
}