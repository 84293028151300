.cart-and-review {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1450px;
  margin: 0 auto;
  
  .cart-page {
    // width: 55%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px;
    @media screen and (max-width: 800px) {
      margin: 10px;
    }
    .cart-header {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgrey;

      .header-block {
        text-transform: capitalize;
        // width: 23%;
        @media screen and (max-width: 800px) {
          padding: 0 10px;
        }
        &:last-child {
          width: 8%;
        }
      }
    }
    button {
      margin-left: auto;
      margin-top: 20px;
    }
    .payment-warning {
      text-align: center;
      font-size: 0.875em;
      margin-top: 40px;
      color: red;
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    padding: 20px;
    & > :first-child {
      margin-right: 20px;
    }
  }
  .direct-detail-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title-center {
      padding-top: 15px;
      margin: auto;
      margin-bottom: 10px;
      font-size: 1.85em;
      text-align: center;
    }
    .input-group {
      display: flex;
      flex-direction: row;
      padding: 20px;
      & > * {
        margin-bottom: 10px;
        padding: 10px;
        text-align: center;
        width: 50%;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .promotion-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 60px;
    border: 2px solid #337ab7;
    border-radius: 10px;
    padding: 20px;

    .title-center {
      padding-top: 15px;
      margin: auto;
      margin-bottom: 10px;
      font-size: 1.85em;
      text-align: center;
      color: #337ab7;
    }

    .content-center {
      text-align: center;
    }

    p {
      margin: 0.5rem 0;
    }

  }

  .coupon-info{
    color:darkgreen;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .promotion-prompt {
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 40px;

    h3 {
      font-size: 26px;
      font-weight: 700;
      color: #ec8983;
    }

    p {
      font-size: 0.8rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;

      .smallerpromotext {
        font-size: 0.6rem;

        a {
          color: #000;
        }
      }

      &.promogreen {
        color: #337ab7;
      }
    }


  }

  .user-address-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title-center {
      padding: auto;
      margin-bottom: 10px;
      font-size: 1.85em;
      text-align: center;
    }
    .userinfo-group {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
      .form-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        padding: 20px;
        .form-heading {
          padding: 10px 15px;
          border-radius: 3px;
          background-color: #212121;
          font-size: 1.2em;
          color: #fff;
          margin-bottom: 10px;
          text-align: center;
        }
        & > input {
          margin-bottom: 10px;
          padding: 10px;
          text-align: center;
        }
        .dropbtn {
          padding: 10px;
          border: 1px solid lightgrey;
          width: 100%;
          cursor: pointer;
        }

        .dropdown {
          position: relative;
          display: inline-block;
        }

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          width: 100%;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }

        .dropdown-content div {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }

        .dropdown-content div:hover {
          background-color: #f1f1f1;
          transition: 0.5s opacity ease-out;
        }

        .dropdown:hover .dropdown-content {
          display: block;
        }

        .dropdown:hover .dropbtn {
          background-color: rgb(222, 222, 222);
        }
      }
    }
    .ship-info {
      margin-top: -10px;
      padding: 0 20px 30px;
      .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: white;
      }

      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: #2196f3;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .discount-group-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      .discount-group {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 20px;
        & > input {
          padding: 10px;
          margin-bottom: 10px;
        }
        & > h3 {
          font-size: 1.25em;
        }
      }
    }
  }
  .review-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .review-header {
      margin: auto;
      margin-bottom: 10px;
      font-size: 1.85em;
    }
    .order-summary {
      text-align: center;
      border: 1px solid #8f8f8f;
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 2em;
      .order-subtitle {
        margin: 0.5em auto;
        font-size: 1.2em;
        text-align: center;
      }
      .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .shipping-address > * {
        display: block;
        text-align: right;
      }
      .table-responsive {
        min-height: 0.01%;
        overflow-x: auto;
        .table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 20px;
          & > tfoot > tr > td {
            text-align: center;
          }
          .right-table-cell > * {
            text-align: right;
          }
        }
      }
    }
  }
}

.removeborder .MuiOutlinedInput-notchedOutline {
  border: none;
}
