.CMSPage-root-wrapper-fullwidth .sectionCMSPage {
    padding: 30px 0;
}

.sectionCMSPage-colourscheme-darkgrey,
.sectionCMSPage_2_column-colourscheme-darkgrey {
    background-color:#777777;
    color:#ffffff;

    a {
        color: #fff;
    }
}

.sectionCMSPage-colourscheme-white,
.sectionCMSPage_2_column-colourscheme-white {
    background-color:#ffffff;
    color:#333;
}

.ol_page_2_column_column.sectionCMSPage_2_column-colourscheme-formdefault {
    background: #e6e6e6;
    padding: 30px 20px;
    box-sizing: border-box;
    text-align: left;
    border: 1px solid #b3b3b3;

    .ol_page_2_column_content_content {


    }

    @media (min-width: 767px){
        &.ol_page_2_column_column1 {
            
        }

        &.ol_page_2_column_column2
        {
            
        }
    }
}

.isCMSPage .section_parallax_image_section_content {
    padding: 60px 30px;

    img {
        width: auto;
    }
}

@media (min-width: 600px){
    .isCMSPage .MuiContainer-fixed {
        max-width: 100%;
    }
}

@media (min-width: 1280px){
    .isCMSPage .MuiContainer-fixed {
        max-width: 1280px;
    }
}

.CMSPage-root-wrapper-fullwidth .sectionCMSPage .section_standard_content {

    padding: 60px 30px;

    p {
        font-size: 18px;
        line-height: 26px;
    }

    img {
        width: auto;

        &.aligncenter {
            margin: 0 auto;
        }
    }

}

.CMSPage-root-wrapper-fullwidth .sectionCMSPage.sectionCMSBlogSingle {
    
    padding: 0 0;

    .section_standard_content {
        padding: 20px 30px 10px;

        p {
            img {
                width: calc(100% + 60px) !important;
                margin: 0 -30px;
            }
        }
    }

    &.ol_section_item_grid {
        padding-top: 80px;
    }


    .ol_section_item_grid_header_section,
    .ol_section_item_grid_text_header_section {
        padding: 0 0 30px;

        font-family: Termina, sans-serif;
        font-size: 30px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: -.015em;
        max-width: 1460px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @media (min-width: 1024px){
            font-size: 40px;
            line-height: 57px;
        }

    }


    .section_quote_block {
        padding: 30px 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        margin: 20px 0 50px;

        p {
            font-family: Termina, sans-serif;
            font-size: 28px;
            line-height: 32px;
            font-weight: 500;
            letter-spacing: -0.015em;
            text-align: left;

            &::before {
                content: "“";
                font-size: 40px;
                // Add other styles for the opening quote here
              }
            
              &::after {
                content: "”";
                font-size: 40px;
                // Add other styles for the closing quote here
              }
        }

        @media (min-width: 960px){
            padding: 30px 80px;

            p {
                font-size: 40px;
                line-height: 57px;
            }
        }
                
    }

    .ol_section_2_column_content_column-has-image {
        margin: 0;
    }

    .section_other_blogs {
        margin: 50px 0 0;
        padding: 20px 30px 10px;

        h2 {
            text-align: center;
            font-family: Termina, sans-serif;
            font-size: 40px;
            font-weight: 500;
            line-height: 57px;
            letter-spacing: -.015em;
        }

        ul {
            margin: 0 -60px 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                margin: 20px 20px 20px;
                flex-basis: 100%;

                @media (min-width: 600px){
                    flex-basis:  calc(50% - 40px);
                }

                @media (min-width: 970px){
                    flex-basis: calc(33% - 40px);
                }

                a { 
                    color: #000;

                    .section_other_blogs_image {
                        width: 100%;
                        height: 0;
                        padding-bottom: 125%;
                        position: relative;
                        background-color: #efefef;
                        display: block;

                        img {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &.isplaceholder {

                            img {
                                top: 40%;
                                transform: translateY(-40%);
                                left: 50%;
                                transform: translateX(-50%);
                                width: 80% !important;
                            }
                        }
                        
                    }

                    
                }

                h3 {
                    font-family: Termina, sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.015em;
                    text-align: center;
                    margin-top: 20px;
                    text-transform: uppercase;

                    a {
                        color: #000;
                    }

                    @media (min-width: 1300px){
                        font-size: 24px;
                        line-height: 36px;
                    }
                }

                p.section_other_blogs_date {
                    text-align: center;
                }
            }
        }

    }
}


.sectionCMSPage .section_image_slider {
    padding: 0 30px;
}

body.isCMSPage .slick-dots li button::before {
    font-size: 16px;
}

body.isCMSPage .slick-next:before, body.isCMSPage .slick-prev:before {
    color: #aaa;
}

.section_3_column_content,
.section_4_column_content {
    padding: 60px 30px;
}

.section_3_column_content_header_section h2,
.section_4_column_content_header_section h2 {
    font-size: 1.8rem;
    margin-bottom: 55px;
}


.section_4_column_content_columns_section {
    margin: 0 -40px;
}

@media (min-width: 600px){
    .section_4_column_content_columns_section {
        display: flex;
        justify-content: space-between;
        margin: 0 -40px;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media (min-width: 720px){
    .section_3_column_content_columns_section {
        display: flex;
        justify-content: space-between;
        margin: 0 -20px;
    }
}

@media (min-width: 960px){
    .section_4_column_content_columns_section {
        margin: 0 -25px;
    }
}

@media (min-width: 1190px){
    .section_3_column_content_columns_section {
        margin: 0;
    }

}

@media (min-width: 1190px){
    .section_4_column_content_columns_section {
        margin: 0 -24px;
    }
}

.ol_page_3_column_column,
.ol_page_4_column_column {
    text-align: center;
    flex-basis: 100%;
    padding: 0 20px;
    margin: 40px 0;

    h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.8rem;
        margin: 20px 0;
    }
}

@media (min-width: 600px){
    .ol_page_4_column_column {
        flex-basis: 50%;
        margin: 0;
        padding: 0;
    }


}

@media (min-width: 720px){
    .ol_page_3_column_column{
        margin: 0;
        flex-basis: 33.33%;
        max-width: 371px;
        padding: 0 30px;
    }
}

@media (min-width: 860px){

    .ol_page_3_column_column {
        padding: 0 20px;
    }

}

@media (min-width: 860px){

    .ol_page_4_column_column {
        padding: 0;
    }

}
@media (min-width: 960px){
    .ol_page_4_column_column {
        flex-basis: auto;
    }
}

.section_2_column_content {
    padding: 30px 20px;
}

@media (min-width: 767px){
    .section_2_column_content_columns_section {
        display: flex;
        justify-content: space-between;
        
    }
}

@media (min-width: 1090px){
    .section_2_column_content_columns_section {
        
    }

}

.ol_page_2_column_column {
    text-align: center;
    flex-basis: 100%;
    padding: 10px 0;

    h3 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.8rem;
        margin: 20px 0;
    }

    @media (min-width: 767px){
        text-align: left;
        margin: 0;
        flex-basis: 50%;

        &.ol_page_2_column_column1 {
            margin: 0 10px 0 0;

            .ol_page_2_column_content_content {
                margin: 0 10px 0 10px;
            }
        }
        &.ol_page_2_column_column2 {
            margin: 0 0 0 10px;

            .ol_page_2_column_content_content {
                margin: 0 10px 0 10px;
            }
        }
    }

    @media (min-width: 1060px){

        &.ol_page_2_column_column1 {
            margin: 0 20px 0 0;

            .ol_page_2_column_content_content {
                margin: 0 20px 0 20px;
            }
        }
        &.ol_page_2_column_column2 {
            margin: 0 0 0 20px;

            .ol_page_2_column_content_content {
                margin: 0 20px 0 20px;
            }
        }
    }
}




.ol_page_3_column_content_button, .ol_page_3_column_content_button:visited, .ol_page_3_column_content_button:focus, .ol_page_3_column_content_button:active,
.ol_page_4_column_content_button, .ol_page_4_column_content_button:visited, .ol_page_4_column_content_button:focus, .ol_page_4_column_content_button:active {
    color: #fff;
    background: #f3a226;
    margin: 10px;
    padding: 15px 20px;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
}

.ol_page_4_column_content_button, .ol_page_4_column_content_button:visited, .ol_page_4_column_content_button:focus, .ol_page_4_column_content_button:active {
    background: #4A4A4A;
    padding: 15px 20px;
        width: auto;
    max-width: none;
}

.ol_page_3_column_content_button:hover,
.ol_page_4_column_content_button:hover {
    color: #fff;
    text-decoration: none;
}

@media (min-width: 1090px){

    .ol_page_3_column_content_button {
        padding: 15px 30px;
        font-size: 1.2rem;
    }
}

@media (min-width: 1110px){
    .ol_page_4_column_content_button {
        padding: 15px 30px;
        font-size: 1.2rem;
    }
}

body.isCMSPage img.alignleft {
    margin: 10px auto 20px;
    max-width: 100%;
    height: auto;
}

body.isCMSPage img.alignright {
    margin: 10px auto 20px;
    max-width: 100%;
    height: auto;
}

body.isCMSPage img.aligncenter {
    margin: 10px auto 20px;
    max-width: 100%;
    height: auto;
    width: auto;
}
body.isCMSPage p img, body.isCMSPage img.alignnone {
    display: inline-block;
}

body.isCMSPage p img.aligncenter  {
    display: block;
}


@media (min-width: 600px){

    body.isCMSPage img.alignleft {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    body.isCMSPage img.alignright {
        float: right;
        margin-left: 20px;
        margin-bottom: 20px;
    }

}

body.isCMSPage .YTvideoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

body.isCMSPage .pb-form-add {
    height: 50px;
    color: #333;
    text-align: center;

    &::before {
        content: 'form loading...';
        color: #333;
    }

    &.pb-form-add-loaded {

        height: auto;
    
        &::before {
            display: none;
        }
    }
}

body.isCMSPage .sectionCMSPage-colourscheme-darkgrey .pb-form-add::before {
    color: #aaa;
}

body.isCMSPage .gform_wrapper {

    ul {
        margin: 0;

        li {
            list-style-type: none;
            text-align: left;

            label {
                color: #000;
                font-size: 0.7rem;
                font-weight: 700;
                margin-bottom: 3px;
            }

            input[type="text"],
            input[type="email"],
            textarea,
            select {
                display: block;
                color: #555;
                border: 1px solid #ccc;
                padding: 10px 16px !important;
                background: #fff;
                outline: none;
                line-height: 1.4 !important;
                border-radius: 6px;
                font-size: 0.7rem !important;
                width: 100% !important;
            }        
        }
    }

    input[type="submit"] {
        display: inline-block;
        font-size: 1rem !important;
        font-weight: 700;
        padding: 10px 16px !important;
        border-radius: 3px;
        margin: 20px 0 0 !important;
        width: 100% !important;
        line-height: 1.4 !important;
        border: 0;
        max-width: 134px;
        background: #337ab7;
        color: #fff;
    }

    .validation_message {
        color: #cc0000;
        font-size: 14px;
    }

    @media (min-width: 641px){
        li.gfield.gf_left_half,
        li.gfield.gf_right_half {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding-right: 16px;
            float: none;
        }
    }
    .gfield_radio li label {
        margin: 2px 0 0 4px;
        font-weight: normal;
    }

    input[type="radio"] {
        margin: 6px 5px 0 1px;
    }
}

body.isCMSPage .sectionCMSPage_2_column-colourscheme-darkgrey .gform_wrapper {

    li label {
        color: #fff;
    }

    input[type="submit"] {
        border: 1px solid #fff;
    }
}


#root .CMSPage-root-wrapper-fullwidth {

    margin-left: 0;
    margin-right: 0;

    @media (min-width: 960px){
        margin-left:-24px;
        margin-right:-24px;
    }

}


#blog-main-wrap {
    margin: -30px -30px 0;

    @media (min-width: 960px){
        margin: -30px -6px 0;
    }

    #blog-banner-wrap {
        max-height: calc(100vh - 130px);
        position: relative;
        overflow: hidden;
        width: 100%;

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }

    #blogmain-intro {
        padding: 30px;
        max-width: 1500px;
        margin: 50px auto 0;

        @media (min-width: 1024px){
            padding: 45px;
        }

        h1 {
            text-align: center;
            margin-bottom: 50px;
        }

        p {
            text-align: center;
            margin: 20px auto;
        }
        

    }

    .blog-post-single-wrapper {
        margin: 0 auto;
        padding: 30px;
        max-width: 1490px;

        @media (min-width: 540px){
            padding: 30px 50px 0;
        }

        h1 {
            text-align: center;
            margin: 30px 0 0;
        }

        .blog-post-single-date {
            font-family: Montserrat, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.015em;
            text-align: center;

        }

        .blog-post-single-content {
            margin: 50px 0;
        }

        img {
            width: 100%;
            height: auto;
        }

    }

    #blog-nav-column {

        margin: 0 auto;
        padding: 30px 30px 30px;

        @media (min-width: 540px){
            padding:30px 50px 30px;
        }

        h3 {
            font-family: Termina, sans-serif;
            font-size: 40px;
            font-weight: 500;
            line-height: 57px;
            letter-spacing: -0.015em;
            text-align: left;
            margin-top: 0px;

        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            a {
                font-family: Termina, sans-serif;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: -0.015em;
                text-align: left;
                color: #ccc;

            }
        }

    }

    .blog-post-summaries-wrapper {
        margin: 0 auto;
        padding: 30px;
        
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 60px;

        @media (min-width: 540px){
            padding: 30px 50px;
        }

        @media (min-width: 768px){
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 900px){
            grid-gap: 100px;
        }

        @media (min-width: 1170px){
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 60px;
        }

        @media (min-width: 1400px){
            grid-gap: 100px;
        }

        .blog-post-summary-img-wrap {
            width: 100%;
            height: 0;
            padding-bottom: 125%;
            position: relative;
            background-color: #efefef;
            display: block;

            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &.noblogimg {

                img {
                    top: 40%;
                    transform: translateY(-40%);
                    left: 50%;
                    transform: translateX(-50%);
                    width: 80%;
                }
            }
        }

        h3 {
            font-family: Termina, sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.015em;
            text-align: center;
            text-transform: uppercase;
            margin-top: 30px;

            a {
                color: #000;
            
            }

        }

        .blog-post-summary-date {
            font-family: Montserrat, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.015em;
            text-align: center;

        }

    }

    .blog-posts-paging {
        margin: 50px auto;
        text-align: center;

        div {
            font-size: 18px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: -0.015em;
            text-align: center;
            color: #ccc;
            margin: 0 10px;
            cursor: pointer;
            display: inline-block;
            border: 1px solid #ddd;
            width: 36px;
            height: 36px;

            &.blog-page-link-active {
                color: #000;
                border: 1px solid #000;
            }
        }
    
    }

}


#blog-columns {
    padding-top: 40px;
    margin-bottom: 100px;

    @media (min-width: 960px){
        display: flex;
        justify-content: space-between;
    }

    #blog-main-column {
        margin-right: 20px;

        @media (min-width: 960px){
            margin-right: 0;
            flex-basis: calc(100% - 310px);
        }

        @media (min-width: 1280px){
            flex-basis: calc(100% - 360px);
        }

        h1 {
            font-size: 1.4em;
            margin-left: 0;
            margin-bottom: 40px;

            @media (min-width: 960px){
                margin-left: 20px;
            }
        }

        .blog-post-single-wrapper {

            h1 {
                margin-bottom: 0;
            }

            .blog-post-single-date {
                padding-bottom: 15px;
                border-bottom: 1px solid #289bcf;
                margin-bottom: 20px;
                margin-top: 10px;
                font-style: italic;
                font-size: 0.8em;
            }

            @media (min-width: 960px){
                margin-left: 20px;

                h1 {
                    margin-left: 0;
                }
            }

            img {
                width: auto;
                max-width: 100%;
                height: auto;

                &.aligncenter {
                    margin: 0 auto;
                }
                &.alignleft {
                    float: left;
                }
                &.alignright {
                    float: right;
                }
            }

        }

        .blog-post-summary-wrapper {
            
            margin: 0 0 60px;

            @media (min-width: 460px){
                display: flex;
                justify-content: space-between;
            }

            .blog-post-summary-col1 {

                width: 220px;
                margin-right: auto;
                margin-bottom: 20px;
                margin-left: auto;

                @media (min-width: 460px){
                    width: calc(32% - 40px);
                    margin-right: 20px;
                    margin-left: 20px;
                    margin-bottom: 0;
                }

                @media (min-width: 1280px){
                    width: calc(32% - 70px);
                    margin-right: 50px;
                }
            }
            
            .blog-post-summary-col2 {
                width: 100%;

                @media (min-width: 460px){
                    width: 68%;
                }

                h3 {
                    font-size: 1.6em;
                    margin-bottom: 0;
                    text-align: center;

                    @media (min-width: 460px){
                        text-align: left;
                        margin-top: -6px;
                    }

                    a {
                        color: #000;
                    }
                }

                .blog-post-summary-date {
                    font-size: 0.8em;
                    font-style: italic;
                    margin-top: 15px;
                    color: #555;
                }

                .blog-post-summary-text {
                    margin-top: 20px;
                }

                .blog-post-summary-readmore a {
                    background: #289bcf;
                    color: #fff;
                    font-size: 0.8em;
                    font-weight: 700;
                    margin-top: 10px;
                    padding: 10px 13px;
                    box-sizing: border-box;
                    text-transform: uppercase;
                    border-radius: 5px;
                    display: inline-block;
                }
            }
            


        }
    }

    #blog-side-column {
        flex-basis: 260px;
        margin-left: 20px;
        margin-right: 20px;

        #blog-side-column-nav {
            background: #e8e8e8;
            padding: 25px;

            h3 {
                font-size: 1.4em;
            }

            ul.blog-categories-nav {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    border-bottom: 1px solid #cfcfcf;

                    a {
                        display: inline-block;
                        padding: 15px 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }

                }

            }
        }
    }
}

/* Style all touch screens
------------------------------------------ */
@media (hover:none) {
//ios fix
    #sectionCMSPage1,
    #sectionCMSPage2,
    #sectionCMSPage3,
    #sectionCMSPage4,
    #sectionCMSPage5,
    #sectionCMSPage6,
    #sectionCMSPage7,
    #sectionCMSPage8,
    #sectionCMSPage9,
    #sectionCMSPage10,
    #sectionCMSPage11,
    #sectionCMSPage12 { //ios fix
        background-attachment: scroll !important;
        background-position: 50% 0 !important;
    }
}

@media (max-width: 1024px){//make backgrounds work better on smaller screens by default
    #sectionCMSPage1,
    #sectionCMSPage2,
    #sectionCMSPage3,
    #sectionCMSPage4,
    #sectionCMSPage5,
    #sectionCMSPage6,
    #sectionCMSPage7,
    #sectionCMSPage8,
    #sectionCMSPage9,
    #sectionCMSPage10,
    #sectionCMSPage11,
    #sectionCMSPage12 {
        background-size: cover;
    }

}


.noauthinfo {

    &.noauthinfocms {
        padding: 20px 0;
    }

    h5 {
        text-align: center;
        position: relative;
        max-width: 560px;
        margin: 20px auto 40px;
        font-size: 18px;
        font-weight: bold;

        @media (min-width: 969px){
            padding: 0 10px 0 100px;
        }

        em {
            display: block;
            font-style: normal;
            font-weight: normal;
        }

        img {
            width: 100px;
            margin: 0 auto 20px;

            @media (min-width: 969px){
                float: right;

            }
        }
    }

    p {
        max-width: 530px;
        margin: 20px auto;
        text-align: center;
    }
}

#cmsinlinelogin {
    display: none;
    max-width: 700px;
    margin: 40px auto 20px;
}

#inlineloginsection {

    .loadingmsginline {
        padding: 16px 24px;
    }

    form {
        padding: 0 24px;

        .MuiDialogContent-dividers {
            padding: 16px 0px;
        }
    }

}
