.collection-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 280px;
  align-items: center;
  position: relative;
  margin-right: 1.2em;
  margin-top: 1.2em;
  padding: 0.5em;
  background: white;
  .saletag {
    width: 100px;
    float: right;
    left: -10px;
    top: 1px;
    z-index: 1;
    position: absolute;
    background-repeat: no-repeat;
  }
  .link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    .image {
      width: inherit;
      height: inherit;
      background-color: white;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 5px;
    }

    &:hover {
      .image {
        opacity: 0.7;
      }

      .custom-button {
        opacity: 0.7;
      }
    }
  }

  .collection-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    margin: 10px;
    .title-and-color {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .name-group {
        display: flex;
        flex-direction: column;
        .name {
          font-size: 14px;
          margin-bottom: 8px;
          display: block;
        }
      }
      .sub-name {
        text-align: right;
        font-size: 12px;
        margin-bottom: 8px;
        display: block;
      }
      .option-size-and-bridge {
        width: 100%;
      }
    }
    .number-group {
      align-items: center;
      font-size: 14px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .name {
        width: 90%;
        font-size: 14px;
      }

      .price-group {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        .price {
          font-size: 14px;
          margin-right: 10px;
        }
        .sales-price {
          font-size: 14px;
          text-decoration: line-through;
          margin-right: 10px;
        }
        .sales-price-red {
          font-size: 16px;
          margin-right: 3px;
          color: red;
        }
      }
    }
  }
}
