

.chip-active{
 background-color:green !important;
 color:white !important;
 margin:5px;
 padding: 10px;
   &.MuiChip-clickable.MuiChip-outlined:focus,
   &.MuiChip-deletable.MuiChip-outlined:focus,
   &.MuiChip-clickable.MuiChip-outlined:hover{
    background-color: green !important;
    color:white;
  }
}

.chip-inactive{
 margin:5px;
 padding: 10px;
 background-color:white;
 color:#575b6e;
}


