.shoppage_content .MuiExpansionPanelSummary-root .MuiTypography-button {
    text-transform: none;
    font-family: Termina,sans-serif !important;
}

.shoppage_main .filterheading {
    font-family: Termina,sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    line-height: 18px;
    margin-top: 21px;
}

.shoppage_main .filterclear {
    color: #009ac7;
    border: 1px solid #009ac7;
    border-radius: 10px;
    font-size: 0.7rem;
    margin-top: 12px;

    &:hover {
        background: #009ac7;
        color: white;
        border: 1px solid #009ac7;
    }
}

.shoppage_main .prodgrid-wrap {
    align-items: stretch;

    > .MuiGrid-item {
        margin-bottom: 30px;
    }
}

.shoppage_main .prodgrid-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.shoppage_general .text-label-chosen {
    font-size: 0.7rem;
}

.shoppage_general .prodgrid-item .custom-button {
    font-size: 0.7rem;
}

.shoppage_general .colourchooserbtn {
    color: #555;
}

.shoppage_general .colourchooser-wrap {
    display: flex;
    width: 100%;
    align-items: center;

    .colourchooser-col2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
        align-items: center;
    }
}

.shoppage_general .MuiSlider-root {
    color: #1E97CE;
}

.shoppage_general .sliderwrapmin30 .MuiSlider-root {
    color: rgba(0,0,0,0.5);
}

.shoppage_general .sliderwrapmin100 .MuiSlider-root {
    color: #000;
}

.shoppage_single {
    //css grid that has template that has 2 columns in first row and 1 column in second row
    

    @media (min-width: 960px) {
        display: grid;
        grid-template-columns: 66.66% 33.33%;
    }

    .singleProduc-heroimage {
        padding: 12px;
    }

    .productcol2 {
        padding: 40px 12px !important;
        
    }

    .singleProducInfoWrap {
        margin-top: 40px;
        

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 340px 1fr;
        }

        @media (min-width: 960px) {
            grid-column: span 2; // make this item span 2 columns
        }
        
        @media (min-width: 1260px) {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 100px;
        }

        .singleProducInfo-col2 {

            margin-top: 100px;

            @media (min-width: 768px) {
                margin-left: 50px;
                margin-top: 0;
            }

            @media (min-width: 960px) {
            margin-left: 100px;
            }

            @media (min-width: 1260px) {
                margin-left: 0;
                grid-column: span 2;
            }

            @media (min-width: 1370px) {
            grid-column: span 1;
            }

        }
    }



    h2 {
        font-family: Termina,sans-serif;
        font-size: 26pt;
        font-weight: 700;
    }

    h3 {
        font-family: Termina,sans-serif;
        font-size: 16pt;
        font-weight: 700;
    }

    .productcol2-details {
        padding: 4px;
        margin-top: 15px;

        span {
            padding: 0 14px 0 0;
        }
    }

    .productcol2-pricing {
        margin-top: 5px;
    }

    .productcol2-colour {
        margin-top: 30px;
        margin-bottom: 10px;
    }



    div.product-descriptions p {
        font-family: Montserrat,sans-serif;
        font-weight: 400;
        letter-spacing: -.015em;
        font-size: 18px;
        line-height: 30px;
        text-align: left;
    }

    .singleProducInfo-table {
        width: 100%;
        
        th, td {
            padding: 10px;
            border-bottom: 1px solid #eee;
            font-weight: 400;
        }

        th {
            width: 160px;
        }
    }

    .btnAddToCart {
        background-color: #000;
        text-align: center;
        color: #fff;
        width: 100%;
        margin: 15px 0 15px;
        text-transform: uppercase !important;

        &:hover {
            background-color: #1E97CE;
        }
    }

}

body #root > div.orderLoading {
    max-width: none;
    margin: 100px auto;
    display: block;

    svg {
        width: 40px;
        height: 40px;
    }

    &.MuiCircularProgress-colorPrimary {
        color: #1E97CE;
    }
}
