$primarySiteColour: #1e97ce;
$primary-color:black;

@import "assets/scss/layouts/mixins";
// @import "node_modules/spectre.css/src/spectre";
html,
body {
  margin: 0;
  background-color: #e6e6e6 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
html {
  width: "100%";
}

a, a:visited, a:focus, a:active, a:hover, button {
  outline: none !important;
  box-shadow: none;
  color: #1e97ce;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

h1 {
  //styleName: Sub-page H1;
font-family: Termina, sans-serif;
font-size: 40px;
font-weight: 500;
line-height: 57px;
letter-spacing: -0.015em;
text-align: left;

}


.text-link {
  color: inherit !important;
  text-decoration: inherit !important;
}

.hidden {
  display:none;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.app-container{
  padding-top:86px;
  background: 'white';
  padding-bottom:100px;
}

.link{
  text-decoration:inherit !important;
  color:inherit !important;
}


.footer{
  background: $primarySiteColour;
  color:white;
  .grid{
    padding:20px;
  }
  .copyright{
    text-align: right;
    padding-right:30px;
    padding-bottom:5px;
  }
}

.footer-special-links {
    text-align: center;

    @include bpmin(laptop) {
      text-align: right;
    }
}

.img-logo {
  /*max-width:220px;
  max-height: 70px;
  margin-right:20px;
  object-fit: contain;*/
  max-width: 280px;
  margin-right:20px;
  margin-left: 10px;

  @include bpmin(laptop) {
    max-width: 220px;
  }

  @include bpmin(desktopsm) {
    max-width: 250px;
  }

  @include bpmin(desktopmed) {
    max-width: 290px;
  }

  @include bpmin(desktop) {
    max-width: 320px;
  }
}
.img-logo-small{
  max-width:190px;
  max-height: 56px;
  object-fit: contain;
}

.modal-container{
  outline: none;
}

.login-padding{
  padding: 20px 30px 10px 20px;
}

//Todo
.MuiAppBar-colorPrimary {
  color: Black !important;
  background-color: white !important;
}

.selected-color{
  color: #F50057 !important;
  text-decoration: none;
}

.MuiButton-root{
  text-transform:none !important;
}

.rootclass-optiquelinecomau .carousel,.rootclass-optiquelineconz .carousel {
  max-height: 400px;
}

#root wishlistButton {
  cursor: pointer;
}

#root .buttonOnShop .wishlistButton {
  position: absolute;
  margin-top: 2px;
  right: 3px;
}

.text-label-chosen-long {
  font-size: 0.7125rem;
}

.text-label-chosen-extralong {
  font-size: 0.6125rem;
}

.custom-cart-stock-message {
  font-size: 13px;
  white-space: nowrap;
  @include bpmin(laptop) {
    position: absolute;
  }
}

@import "assets/scss/layouts/staticpage";
@import "assets/scss/layouts/cmspages";
@import "assets/scss/layouts/stockists";

@import "assets/scss/layouts/header";
@import "assets/scss/layouts/footer";
@import "assets/scss/layouts/cmspagesv2";
@import "assets/scss/layouts/shop";