 .desc{
   padding-bottom:40px;
 }

 div.product-descriptions {
  padding-bottom:20px;
 }

 div.product-descriptions p {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
 }

 .MuiTableCell-sizeSmall{
   padding-left:0 !important;
 }

 .MuiTypography-subtitle1{
   font-weight:bold !important;
 }

 .no_product_container {
   height: 500px;
 }