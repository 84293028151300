body.hoveropen {
    padding-top: 80px;
}

body #root {
    background-color: #fff;
    font-family: Montserrat, sans-serif;
}

body .MuiInputBase-root,
body .MuiFormLabel-root,
body .MuiTypography-subtitle1,
body .MuiButton-root,
body .MuiTypography-button,
body .MuiTypography-h6,
body .MuiChip-root,
body .MuiTypography-body1 {
    font-family: Montserrat, sans-serif;
}

body #root select {
    appearance: none; // remove default select style
    padding-right: 30px; // space for the new arrow
    position: relative;
    background: transparent; // make the select box transparent
}

body #root .ginput_container_select {
    position: relative;
    &::after {
        content: ""; // empty content
        border-left: 5px solid transparent; // left side of the arrow
        border-right: 5px solid transparent; // right side of the arrow
        border-top: 5px solid #666; // top side of the arrow
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

body #root > div {
    max-width: 2130px;
    padding: 30px;
    margin: 0 auto;
}

body #root > div.cart-and-review {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1450px;
    margin: 0 auto;
}

body #root > div#olsitev2-page-main {
    width: 100%;
    padding: 0;
}

header.mainsite {
    background-color: #101212;
    color: #fff;
    display: grid;
    grid-template-columns: auto auto; /*316px calc(100% - 870px) 480px;*/
    grid-gap: 20px;
    padding: 20px 20px;
    justify-content: space-between;
}

header.landingpage {
    padding: 10px 20px;
    background-color: #101212;
    color: #fff;
    display: grid;
    justify-content: center;

    @media (min-width: 470px) {
        display: grid;
        grid-template-columns: calc(100% - 180px) 180px; /*316px calc(100% - 870px) 480px;*/
        grid-gap: 20px;
        align-items: center;
    }

    .headercol-logo {
        display: grid;
        align-items: center;

        @media (min-width: 470px) {
            padding-left: 20px;
        }
        

        @media (min-width: 767px) {
            padding-left: 180px;
            justify-content: center;
        }
    }

    .headercol-nav {
        padding-bottom: 20px;

        @media (min-width: 470px) {
            width: 150px;
            padding-bottom: 0;
        }

        ul.landingmainnav {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-family: Montserrat, sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.015em;
                text-align: center;

                a {
                    color: #fff;
                }
            }
        }
    }
}

body.hoveropen header.mainsite {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

header.mainsite .headercol-logo {
    padding-right: 30px;
}

header.mainsite .mainlogo,
header.landingpage .mainlogo {
    width: 200px;
    height: auto;
    margin-top: 5px;
    // margin-left: 30px;
    // margin-right: 30px;
}

header.mainsite .headerbuttons {

    .btn-headerbuttons {
        padding: 11px 11px;
        border-radius: 6px;
        border: 1px;
        background-color: #fff;
        color: #101212;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -1.5%;
        text-align: center;
        cursor: pointer;

        &.btn-headerbuttons-register {
            background-color: #1E97CE;
            color: #fff;
            position: fixed;
            right: -22px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            z-index: 1299;
            border-radius: 6px 6px 0 0; // adjust these values as needed
        }

    } 
}

.navopen header.mainsite .headerbuttons .btn-headerbuttons-register  {
    display: none;
}


header.mainsite .headercol-nav {
    display: none;
}

.navopen header.mainsite .headercol-nav {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1;
    background-color: #101212;
    width: 100%;
}

header.mainsite .headercol-utils {
    display: grid;
    grid-template-columns: auto auto 64px;
    grid-gap: 30px;
    justify-content: end;
    justify-items: end;
    padding-left: 30px;
}

header.mainsite .headercol-utils label {
    font-size: 14px;
}


.headercol-menutoggle {
    cursor: pointer;
    width: 35px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 7px;
  }
  
  .headercol-menutoggle span {
    width: 100%;
    height: 4px;
    background-color: #fff; /* adjust color as needed */
    transition: transform 0.3s ease, opacity 0.3s ease; /* add this line */
  }
  
  .navopen {
      .headercol-menutoggle span:nth-child(1) {
          transform: rotate(45deg) translate(7px, 8px); // reduced the y value
      }
  
      .headercol-menutoggle span:nth-child(2) {
          opacity: 0;
      }
  
      .headercol-menutoggle span:nth-child(3) {
          transform: rotate(-45deg) translate(7px, -8px); // increased the y value
      }
  }



@media (max-width: 1023px) {

  .megamenu-featured {
    display: none;
  }

  ul.mainsitenav {
    list-style: none;
    padding: 0 0 40px;
    margin: 0 auto;
    border-top: 1px solid #999;
    font-size: 18px;

    li {
        border-bottom: 1px solid #999;
        padding: 10px 20px;
        margin: 0;
        position: relative;

        span.subnavtoggle {
            position: absolute;
            right: 10px;
            top: 0;
            width: 55px;
            height: 45px;

            &::after {
                content: "";
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid #fff; // change this line
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.subnavopen {

            span.subnavtoggle {
                &::after {
                    content: ""; // add this line
                    border-left: 8px solid transparent;
                    border-right:8px solid transparent;
                    border-top: 8px solid #fff;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .pbmeganav-content {
                display: block;
            }

        }

        .pbmeganav-content {
            display: none;
        }
    }


    a {
        color: #fff;
    }

    span {
        color: #fff;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 20px;

        li {
            padding: 10px 0 0 0;
            margin: 0;
            font-size: 16px;
            text-decoration: none;
            border-bottom: none;

            a {
                color: #c0c0c0;
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
}

  }

  @media (max-width: 779px) {

    header.mainsite {
        grid-gap: 0;

        .headercol-utils {
            grid-gap: 0;
            padding-left: 0;
            grid-template-columns: auto auto auto;
        }
    }

    .MuiGrid-container.makeStyles-searchContainer-8,
    .btn-headerbuttons-login,
    .btn-headerbuttons-logout,
    .accountLinkIcon {
        display: none;
    }

    .cartLinkIcon {
        margin-right: 20px;
        margin-top: 5px;
        display: inline-block;
    }

    .navopen {


        header.mainsite .headercol-nav {
            top: 75px;
            border-top: 1px solid #ccc;
        }

        .MuiGrid-container.makeStyles-searchContainer-8 {

            position: absolute;
            left: 20px;
            top: 89px;
            z-index: 999;
            display: flex;

        }

        .btn-headerbuttons-login,
        .btn-headerbuttons-logout {
            display: block;
            position: absolute;
            right: 25px;
            top: 89px;
            z-index: 999;
        }

        .accountLinkIcon {
            display: block;
            position: absolute;
            right: 125px;
            top: 93px;
            z-index: 999;
        }
    }

    ul.mainsitenav {
        margin-top: 66px;
    }


  }

  @media (max-width: 355px) {

    header.mainsite {
        padding: 20px 10px;

        .headercol-logo {
            padding-right: 10px;
        }
    }

  }




  @media (min-width: 780px) {

    header.mainsite .headerbuttons {        

        &.headerbuttons-loggedin {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-gap: 20px;
            align-items: center;
            justify-content: end;
        }
    }
  }



@media (min-width: 1024px) {
    header.mainsite {
        grid-template-columns: auto auto auto;

        .headercol-nav {
            display: block;

            ul.mainsitenav {
                list-style: none;
                padding: 0;
                margin: 0 auto;
                display: grid;
                grid-template-columns: auto auto auto;
            
                .navlargeonly {
                    display: none;
                }
            }
            
            ul.mainsitenav>li {
                font-size: 15px;
                padding: 0 0 0 30px; 
            }
            
            ul.mainsitenav>li:first-of-type {
                padding: 0 0 0 0; 
            }
            
            ul.mainsitenav li a {
                color: #fff;
                text-decoration: none;
            }
            
            ul.mainsitenav li:hover>span, 
            ul.mainsitenav li:hover>a {
                color: #009AC7;
                cursor: pointer;
            }
            
            ul.mainsitenav ul {
                margin-top: 100px;
                list-style-type: none;
                padding: 0;
                margin: 0;
                color: #D9D9D9;
                font-family: Montserrat, sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.27px;
            }

        }
    
        .headercol-utils {
            grid-template-columns: auto auto;

            .headercol-menutoggle {
                display: none;
            }
        }  
    }

    .pbmeganav {
        // position: relative;
    
        &:hover {
    
            .pbmeganav-content {
                margin-top: 65px; 
                display: grid;
                // grid-template-columns: auto 300px;
               
            }
    
            .megamenu-featured { 
                display: block;
                margin-top: 95px; 
    
                h3 {
                    color: #D9D9D9;
                    font-family: Termina, sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.225px;
                    text-transform: uppercase;
                }
            }
    
            .megamenu-background {
                opacity: 1;
                content: "";
                position: fixed;
                top: 80px;
                left: 0;
                width: 100vw;
                height: calc(50vh - 55px);/*65 = half the header height*/
                min-height: 400px;
                background: rgba(0, 0, 0, 0.80);
                z-index: 2;
            }
    
            .megamenu-background-wrapper {
                content: "";
                position: absolute;
                top: 46px;
                width: 190px;
                height: 100px;
                z-index: 2;
                margin-left: -20px;
            }
        }
    
        .pbmeganav-content {
            display: none;
            position: absolute;
            z-index: 1000; // adjust this value as needed
            width: 800px;
        }
    
        .megamenu-featured {
            display: none;
            position: absolute; // changed from fixed to absolute
            right: 30px; // added this line
            width: 330px;
            z-index: 1000; // adjust this value as needed
        }
    
        .megamenu-background {
            opacity: 0;
            //transition: opacity 0.3s ease-in-out;
        }
    
        ul.mainsitenav-subnav li {
            margin-bottom: 20px
        }
    }
}


@media (min-width: 1160px) {

    header.mainsite {
        grid-gap: 30px;
        padding: 30px;

        .mainlogo {
            width: 256px;
            margin-top: 0;
        }

        ul.mainsitenav>li {
            padding: 0 0 0 30px; 
            font-size: 18px;
        }

        ul.mainsitenav>li:first-of-type {
            padding: 0 0 0 0; 
        }

        ul.mainsitenav ul {
            font-size: 18px;
        }

        .headercol-utils label {
            font-size: 14px;
        }
    }


    body.hoveropen {
        padding-top: 100px;
    }
    .pbmeganav {
        // position: relative;
    
        &:hover {

            .pbmeganav-content {
                margin-top: 75px; 
            }

            .megamenu-background {
                top: 100px;
            }

            .megamenu-background-wrapper {
                top: 55px;
            }
        }
    }

}

@media (min-width: 1340px) {

    header.mainsite {
        padding: 30px;

        .headercol-nav {

            ul.mainsitenav {
                grid-template-columns: auto auto auto auto;
            
                .navlargeonly {
                    display: initial;
                }
            }

            .headerbuttons {

                .btn-headerbuttons {
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }

    }

    .pbmeganav {
        // position: relative;
    
        &:hover {

            .pbmeganav-content {
                margin-top: 75px; 
            }

            .megamenu-featured {
                width: 425px;
            }

            .megamenu-background {
                height: calc(50vh - 10px);
            }
        }
    }
}

@media (min-width: 1450px) {

    header.mainsite {

        grid-template-columns: 290px calc(100% - 825px) 470px;

    }
}



@media (min-width: 1590px) {

    body.hoveropen {
        padding-top: 130px;
    }

    header.mainsite {
        padding: 45px;
        grid-template-columns: auto 675px auto;

        .headerbuttons {

            .btn-headerbuttons {
                width: 130px;
                padding: 11px 0;

                &.btn-headerbuttons-logout {
                    width: auto;
                    padding: 11px 11px;
                }

                &.btn-headerbuttons-register {
                    right: -46px;
                }
            }
        }

    }
    .pbmeganav {
        // position: relative;
    
        &:hover {

            .pbmeganav-content {
                margin-top: 95px; 
            }

            .megamenu-background {
                top: 130px;
            }

            .megamenu-featured {
                right: 65px;
            }

            .megamenu-background-wrapper {
                top: 70px;
            }
        }
    }    
}

.hidesignuppopup {
    display: none;
}
