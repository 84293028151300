.CMSPage-root-wrapperV2 {
    background-color: #fff;
    font-family: Termina, sans-serif;
}

.CMSPage-root-wrapperV2 h1, 
.CMSPage-root-wrapperV2 h2, 
.CMSPage-root-wrapperV2 h3, 
.CMSPage-root-wrapperV2 h4 {

    font-family: Termina, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: -0.015em;
  max-width: 1460px;
  margin-left: auto;
  margin-right: auto;
  }
  
  .CMSPage-root-wrapperV2 h2 {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.015em;
  }

  .CMSPage-root-wrapperV2 h3 {
    font-size: 22px;
    line-height: 32px;
  }

  .CMSPage-root-wrapperV2 h4 {

    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.015em;

    }

    .CMSPage-root-wrapperV2 .ol_section_2_column_content_header_section h4, .sectionCMSPage2 .section_standard_content h4  {
        margin: 50px auto 0;
    }

    .sectionCMSPage2 .section_standard_content  {
        padding: 45px 0;
    }

  .CMSPage-root-wrapperV2 a {
    text-decoration: underline;
    color: #333;
  }

.CMSPage-root-wrapperV2 a.smaller {
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    font-family: Termina, sans-serif;
  }
  
  .CMSPage-root-wrapperV2 h2.h1style {
    font-family: Termina, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: -0.015em;
  }
  
.CMSPage-root-wrapperV2 h2.largemontserrat {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.015em;
  }

.CMSPage-root-wrapperV2 p,
.CMSPage-root-wrapperV2 ul,
.CMSPage-root-wrapperV2 ol {
font-family: Montserrat, sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 30px;
letter-spacing: -0.015em;
text-align: left;
max-width: 1460px;
margin-left: auto;
margin-right: auto;
}

.CMSPage-root-wrapperV2 img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.CMSPage-root-wrapperV2 .sectionCMSPage {
    padding: 30px;
}
.CMSPage-root-wrapperV2 .sectionCMSPage1 {
    padding: 0;
}

.CMSPage-root-wrapperV2 .ol_section_2_column_content_header_section, 
.CMSPage-root-wrapperV2 .ol_section_item_grid_header_section, .CMSPage-root-wrapperV2 .ol_section_item_grid_text_header_section {
    padding: 30px 0;
}


@media (min-width: 1024px) {

    .CMSPage-root-wrapperV2 .sectionCMSPage {
        padding: 45px;
    }
    .CMSPage-root-wrapperV2 .sectionCMSPage1 {
        padding: 0;
    }
    
    .CMSPage-root-wrapperV2 .ol_section_2_column_content_header_section, 
    .CMSPage-root-wrapperV2 .ol_section_item_grid_header_section, 
    .CMSPage-root-wrapperV2 .ol_section_item_grid_text_header_section {
        padding: 45px 0;
    }

    .CMSPage-root-wrapperV2 h1,
    .CMSPage-root-wrapperV2 h2.h1style {
        font-size: 40px;
        line-height: 57px;
    }

    .CMSPage-root-wrapperV2 h2,
    .CMSPage-root-wrapperV2 h2.largemontserrat {
        font-size: 36px;
        line-height: 50px;
    }

    .CMSPage-root-wrapperV2 h3 {
        font-size: 24px;
        line-height: 36px;
      }

}




.CMSPage-root-wrapperV2 .ol_section_background_image_section {

    position: relative;
    overflow: hidden;
    width: 100%;
    max-height:100vh;

}

.CMSPage-root-wrapperV2 .ol_section_background_image_section.ol_section_background_image_section1 {
    max-height:calc(100vh - 130px);
}

.CMSPage-root-wrapperV2 .ol_section_background_image_section_content {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-70%, -50%);

}

@media (max-width: 766px) {
    .ol_section_2_column_content_column, .ol_section_2_column_content_column1.ol_section_2_column_content_column-has-image {
        margin-bottom: 45px;
    }

    .ol_section_2_column_content_column-has-image {
        margin: 0 -45px;
    }
}

@media (min-width: 767px) {
    .ol_section_2_column_content_columns_section {
        display: grid;
        grid-gap: 90px;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    .ol_section_2_column_content_column {
        margin-bottom: 0;
    }
    
    .sectionCMSPage-colourscheme-blackwithnarrow .ol_section_2_column_content_columns_section {
        grid-template-columns: 2fr 1fr;
        grid-gap: 50px;
    }
    
    
    .sectionCMSPage-colourscheme-blackwithnarrow .ol_section_2_column_content_columns_section .ol_section_2_column_content_column2 p {
        text-align: right;
    }
    
    .ol_section_2_column_content_column-has-image {
        margin: 0 -45px 0;
    }
}

@media (min-width: 1024px) {

    .sectionCMSPage-colourscheme-blackwithnarrow .ol_section_2_column_content_columns_section {
        grid-template-columns: 3fr 1fr;
        grid-gap: 50px;
    }

}

a.ol-white-button-blue-border-v2,
a.ol-white-button-black-border-v2,
a.inlinetriggerloginpopup,
a.ol-blue-button-v2,
a.ol-blue-button-v2-large,
body.isCMSPage .sectionCMSPage .gform_wrapper input[type=submit] {

    color: #1e97ce;
    border: 1px solid #1e97ce;
    margin-bottom: 20px;
    background-color: #fff;
    font-weight: 500;
    line-height: 21px;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    padding: 10px 16px;
    height: 100%;
    margin: 10px auto 0;
    font-size: 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background-color: #1e97ce;
        color: #fff;
    }

}

a.ol-white-button-black-border-v2,
a.inlinetriggerloginpopup {
    color: #333;
    border: 1px solid #333;

    &:hover {
        background-color: #333;
        color: #fff;
    }
}

body.userloggedin a.inlinetriggerloginpopup {
display: none;
}


a.ol-blue-button-v2,
a.ol-blue-button-v2-large {
    background-color: #1e97ce;
    color: #fff;

    &:hover {
        background-color: #fff;
        color: #1e97ce;
    }
}

a.ol-blue-button-v2-large {
    padding: 15px 50px;
    font-size: 17px;
    line-height: 21px;
}

body.isCMSPage .sectionCMSPage .gform_wrapper input[type=submit] {

    font-size: 17px !important;
    font-weight: 500;
    line-height: 21px !important;
    letter-spacing: -0.015em;


    color: #333;
    border: 1px solid #333;

    &:hover {
        background-color: #333;
        color: #fff;
    }

}

.sectionCMSPage-colourscheme-lightgrey {
    background-color: #f5f5f5;
}

.sectionCMSPage-colourscheme-blackwithnarrow {
    background-color: #000;
    color: #fff;


}

.ol_section_item_grid_style_compressed, .ol_section_item_grid_text_style_compressed {
    max-width: 1350px;
    margin: 0 auto;
}

.ol_section_item_grid_items_grid, .ol_section_item_grid_text_items_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 45px;
    align-items: center;
}

.ol_section_item_grid_items_grid.ol_section_item_grid_items_grid_style_compressed, .ol_section_item_grid_text_items_grid.ol_section_item_grid_text_items_grid_style_compressed {
   max-width: 440px;
    margin: 0 auto;
}

.ol_section_item_grid_item-empty, .ol_section_item_grid_text_item-empty {
    display: none;
}


@media (min-width: 670px) {
    .ol_section_item_grid_items_grid, .ol_section_item_grid_text_items_grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .ol_section_item_grid_items_grid.ol_section_item_grid_items_grid_style_compressed, .ol_section_item_grid_text_items_grid.ol_section_item_grid_text_items_grid_style_compressed {
        max-width: none;
         margin: 0 auto;
     }


}

@media (min-width: 1024px) {
    .ol_section_item_grid_items_grid, .ol_section_item_grid_text_items_grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .ol_section_item_grid_items_grid.ol_section_item_grid_items_grid_cols_2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .ol_section_item_grid_item-empty, .ol_section_item_grid_text_item-empty {
        display: block;
    }
}



.ol_section_item_grid_items_grid p {
    text-align: center;
}

.ol_section_item_grid_item_content a {
    display: inline-block;
}

.CMSPage-root-wrapperV2 .gform_footer {
    text-align: right;
}


body.isCMSPage .gform_wrapper li.gfield {

    padding-bottom: 10px;

}

.CMSPage-root-wrapperV2 .sectionCMSPage.sectionCMSPage-bgvideo {
    padding: 0;
}

.section_bg_video_section-v2 video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  .ol_section_table_table {
    width: 100%;
    border-collapse: collapse;
  }

    .ol_section_table_table th {
        text-align: center;
        border-bottom: 3px solid #000;
        padding: 0 10px;
        display: none;

        @media (min-width: 620px) {
            display: table-cell;
        }
    }

    .ol_section_table_table tr {
        padding-bottom: 30px;
        display: block;

        @media (min-width: 620px) {
            display: table-row;
            padding-bottom: 0;
        }

    }

    .ol_section_table_table td {
        text-align: center;
        border-bottom: 1px solid #eee;
        display: block;
        padding-bottom: 5px;

        @media (min-width: 620px) {
            display: table-cell;
            border-bottom: 1px solid #000;
            padding: 0 10px;
        }
    }

    .ol_section_table_table th p, .ol_section_table_table td p {
        margin: 5px 0;
        padding: 0;
        text-align: center;
    }

    .ol_section_table_table th p, .ol_section_table_table td p {
        line-height: 1.3;
        font-size: 16px;

        @media (min-width: 767px) {
            font-size: 18px;
        }
    }

    .ol_section_table_row_inline_heading {
        font-size: 12px;
        line-height: 14px;
        color: #666;
        display: block;
        margin-top: 10px;

        @media (min-width: 620px) {
            display: none;
        }
    }



    body.isCMSPage input[type="checkbox"]:checked {
        background-color: #1e97ce;
        border-color: #1e97ce;
    }