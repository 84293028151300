.colour-swatch,
.colour-swatch-small {
  border-radius: 50px;
  width: 22px;
  height: 22px;
  background: red;
  display: inline-block;
  margin-right: 5px;
  margin-top: 2px;
  vertical-align: top;
}
.colour-swatch-small {
  width: 18px;
  height: 18px;
  margin-top: 3px;
}
.item-inline{
  display: inline-block;
}


.card {
  max-width: 345px;
  padding-top:56%;
  height: 140px;
}
.media {
  height: 140px;
}


.select-button{
  max-width:140px;
  min-width:140px;
  width:140px;
  .icon{
    display: inline-block;
  }
  .text-label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
    min-width: 1px;
    display: inline-block;
    margin-top:2px;

    &.text-label-chosen{
      float:left;
    }
  }

  .colour-swatch {
    margin-top:2px;
    float:left;
  }
}

.MuiSelect-selectMenu{
  padding:12px 10px 8px 14px  !important;
}

.spacing-bottom{
  margin-bottom:20px !important;
}

.spacing-top{
  margin-top:30px !important;
}