.stockists-root-wrapper {
    padding-top: 34px;
    height: 80vh;

    .iframe-wrapper {
        margin: 0 -32px;
        height: calc(100% - 26px);

        iframe {
            width: 100%;
            overflow: hidden;
            height: 100%;
            border: 0;
        }

    }
    
}