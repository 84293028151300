.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  &.quantity {
    text-align: center;
  }
  .image-container {
    width: 18%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    width: 22%;
  }
  .quantity,
  .price {
    width: 17%;
  }

  .quantity {
    display: flex;
    .arrow {
      cursor: pointer;
      padding: 16px 0;
    }
    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    padding-left: 30px;
    cursor: pointer;
  }
}
