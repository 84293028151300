figure {
  width: 100%;

  @media screen
  and (max-width: 300px) {
    height:500px;
  }

  margin: 0 1em 5em 0;
  background-repeat: no-repeat;
  cursor: zoom-in !important;
  user-select: none;
  &.hideMe:hover .img-back {
    opacity: 0;
    user-select: none;
  }
}



img, .img-back {
  display: block;
  width: 100%;
  pointer-events: none;
}
.img-back{
  padding: 5% 0;
  img{
    padding: 10% 0;
  }
}

