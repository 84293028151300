.custom-button {
  position: relative;
  width: 100%;
  color: white;
  background: #000;
  border: none;
  border-radius: 4px;
  padding: 10px;
  box-shadow: #99c6d1;
  transition: background-color 0.1s ease-out;
  text-transform: uppercase;
  &:hover {
    background: #1e97ce;
    cursor: pointer;
  }
  &.fixed-width {
    margin: 0px auto;
    width: 60%;
    position: relative;
    color: white;
    background: #000;
    border: none;
    border-radius: 4px;
    padding: 4px;
    box-shadow: #99c6d1;
    transition: background-color 0.1s ease-out;
    &:hover {
      background: #1e97ce;
    }
  }
  &.discount {
    margin-right: auto;
    width: 100%;
    position: relative;
    color: white;
    background: #000;
    border: none;
    border-radius: 4px;
    padding: 4px;
    box-shadow: #99c6d1;
    transition: background-color 0.1s ease-out;
    &:hover {
      background: #1e97ce;
    }
  }
  &.btn-checkout {
    width: auto;
    font-size: 25px;
    padding: 10px 16px;
    &:hover {
      background: #1e97ce;
    }
  }
}
